var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pre-account-main"},[_c('div',{staticClass:"pre-account-logo"}),_c('div',{staticClass:"pre-account-background"},[_vm._m(0),(_vm.isResetPassword)?_c('div',{staticClass:"pre-account-form-instruction"},[_vm._v(" Create a new password for your account"),_c('br'),_c('b',[_vm._v(_vm._s(_vm.emailDisplay))])]):_c('div',{staticClass:"pre-account-form-instruction"},[_vm._v(" Create a password for your new account"),_c('br'),_c('b',[_vm._v(_vm._s(_vm.emailDisplay))])]),_c('div',{staticClass:"pre-account-form"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"pre-account-form-body"},[_c('div',{staticClass:"pre-account-form-row"},[_c('ValidationProvider',{attrs:{"vid":"password","name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"form-control",class:{
                    'is-invalid': errors.length > 0,
                  },attrs:{"type":"password"},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"pre-account-form-row"},[_c('ValidationProvider',{attrs:{"vid":"confirmpassword","name":"confirm password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.confirmPassword),expression:"form.confirmPassword"}],staticClass:"form-control",class:{
                    'is-invalid': errors.length > 0,
                  },attrs:{"type":"password"},domProps:{"value":(_vm.form.confirmPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "confirmPassword", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('p',{class:[
              'text-center',
              {
                'text-white': _vm.responseTextError,
              } ]},[_vm._v(" "+_vm._s(_vm.responseText)+" ")]),_c('div',{staticClass:"pre-account-form-footer"},[_c('button',{staticClass:"btn btn-iris",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.isResetPassword ? 'Change Password' : 'Activate Account')+" ")])])])]}}])})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pre-account-heading"},[_c('div',{staticClass:"pre-account-heading-line-1"},[_vm._v("Welcome to")]),_c('div',{staticClass:"pre-account-heading-line-2"},[_vm._v("IrisPro E-Warranty System")])])}]

export { render, staticRenderFns }